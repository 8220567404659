import Link from 'next/link'
import styles from './linkButton.module.css'
import classNames from 'classnames'
import { upperFirst } from 'lodash'

type Props = {
  children: React.ReactNode,
  className?: string,
  variant?: 'tiny' | 'small' | 'medium' | 'large' | 'smallText',
  color?: 'rose' | 'teal' | 'pink' | 'blue' | 'orange' | 'green' | 'black' | 'navy' | 'purple' | 'gray',
  href: string,
  isSecondary?: boolean,
  isDisabled?: boolean,
  noWrap?: boolean,
  isExternal?: boolean,
}

const LinkButton = ({
  href,
  children,
  className = '',
  variant = 'large',
  isSecondary = false,
  isDisabled = false,
  color = 'rose',
  noWrap = false,
  isExternal = false,
}: Props) =>
  <Link href={href}>
    <a
      target={ isExternal ? '_new' : undefined }
      className={classNames(
        className,
        styles[variant],
        // styles[`buttonColor${upperFirst(color)}`],
        {
          [styles.secondary]: isSecondary,
          [styles.isDisabled]: isDisabled,
          [styles.buttonNoWrap]: noWrap,
        },
        `bg-${color}-600 hover:bg-${color}-500 focus:ring-${color}-500`,
      )}
    >
      {children}
    </a>
  </Link>

export default LinkButton
