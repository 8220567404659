import styles from './RepurposingView.module.css'
import {
  UsersIcon,
  ChartBarIcon,
  // PhotographIcon,
  SparklesIcon,
  ClockIcon,
  CurrencyDollarIcon,
  // DocumentTextIcon,
} from '@heroicons/react/outline'

import Footer from 'components/Footer'
import Navigation from 'components/Navigation'
import LinkButton from 'components/LinkButton'
import BlotterLogo from 'components/BlotterLogo'
import HowItWorksDiagram from 'components/HowItWorksDiagram'
import { ImSleepy } from 'react-icons/im'

const RepurposingView = () =>
  <div className={styles.repurposingView}>
    <div className='absolute top-5 left-1 sm:top-2 sm:left-1 ml-2'>
      <BlotterLogo size='small' />
    </div>
    <Navigation />

    <main>
      <div className='w-full flex flex-col items-center'>
        <div className='mt-12 sm:mt-32 text-center mx-auto max-w-[98vw] md:max-w-5xl'>
          <div className='sm:flex items-center'>
            <div className={styles.heroIcon}>
              <HowItWorksDiagram iconOnly isSmall />
            </div>
            <div className={styles.headlineText}>
              <h1 className='text-[7vw] text-cerise-500 sm:text-4xl md:text-[2.3rem] tracking-tight !leading-tight px-4 md:px-0 py-4 sm:py-0'>
                {/* Turn your blog posts into Twitter threads,
                automatically. */}
                {/* Automatically turn your blog posts into Twitter threads to grow your audience. */}
                {/* Quality Twitter Threads and Tweets in Your Own Style. Automatically. */}
                {/* Your blog can do so much more than just SEO */}
                You’re wasting your blog posts<br />
                <span className='text-navy-500'>if they’re just for SEO</span>
                {/* <span className='text-slate-500/80'>if they’re just for SEO</span> */}
                {/* Don’t you see?!?<br />
                The power was inside you <span className='italic'>all along</span>, blog post! */}
              </h1>
              <h2 className='text-[5vw] sm:text-2xl md:text-[1.5rem] mt-6 mb-10 text-navy-300/90 tracking-tight px-4 md:px-0'>
                {/* You spend hours writing valuable content for your blog posts. Now you can easily unlock that value to grow your audience. */}
                {/* You’ve already spent hours writing great content for your blog posts. */}
                {/* You spend hours crafting great blog posts. Blotter lets you make the most of that hard work, saving you time and money. */}
                {/* You spend hours writing great blog posts. Blotter crafts them into Tweets and Twitter threads for you to share, growing your audience while saving you time and money. */}
                {/* Blotter Sharing grows your following by automagically repurposing your blog post content for Twitter. */}
                <span>Blotter Sharing unlocks the audience-growing superpower that is ALREADY in your blog posts — by automatically repurposing your blog post content for Twitter.</span>
                <br /><span className='md:text-base'>LinkedIn + Medium coming soon!</span>
{/*
                Blotter lets you make more of your hard work — so you save time and grow your audience faster. */}
              </h2>
            </div>
          </div>
          {/* <p>Blotter is a new kind of blog comment system that is Twitter-powered and focused on conversation.</p> */}
          <div className='relative mt-2 mb-32 mx-auto flex justify-center'>
          <LinkButton
            href='/sharing/start'
            variant='medium'
            color='rose'
            className='max-w-md mx-auto text-center'
          >Share your work to grow your audience &raquo;</LinkButton>
          <div className='text-[0.75rem] uppercase absolute -bottom-6 text-sky-700 font-bold left-0 right-0'>
              Start for Free
          </div>
          </div>
        </div>
      </div>

      {/* Gradient Feature Section */}
      <div className='mt-40 bg-gradient-to-r from-navy-500 to-navy-400'>
        <div className='max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8'>
          <h2 className='text-3xl font-extrabold text-white tracking-tight'>Sharing your work should be easy</h2>
          <p className='mt-4 max-w-prose text-lg text-purple-100'>
            Alas, there’s a lot of drudgery involved. Blotter helps you live your best life and focus on the work only you can do.
            {/* Someday there might be an AI that can write as well as you, but that day isn’t today. Blotter uses AI <span className='italic'>only</span> to <span className='italic'>convert</span> your high-quality content into high-quality shareable snippets, customized for each platform you use. */}
          </p>
          <div className='mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16'>
            {benefits.map((feature) => (
              <div key={feature.name}>
                <div>
                  <span className='flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10'>
                  <div aria-hidden='true'>{ feature.icon }</div>
                  </span>
                </div>
                <div className='mt-6'>
                  <h3 className='text-lg font-medium text-white'>{feature.name}</h3>
                  <p className='mt-2 text-base text-purple-100' dangerouslySetInnerHTML={{ __html: feature.description }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='mt-40'>
        <div className='max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8 text-center'>
          <h2 className='text-3xl font-extrabold tracking-tight'>Your blog posts go in.</h2>
          <h2 className='text-3xl font-extrabold tracking-tight pt-2'>Your Tweets for the next week come out. </h2>
          <p className='mt-4 max-w-3xl text-lg mx-auto'>
            It’s quick and easy to get started with Blotter’s blog post-to-Tweet conversion service. One click to set up your account, then a couple of clicks to submit your blog’s URL.
          </p>
          <div className='relative my-24'>
            <HowItWorksDiagram showAllSteps />
          </div>
        </div>
      </div>

      <div className='mt-40'>
        <div className='max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8 text-center'>
          <h2 className='text-3xl font-extrabold tracking-tight'>Frequently Asked Questions</h2>
          {/* <p className='mt-4 max-w-3xl text-lg mx-auto'>
            It’s super quick and easy to get started with Blotter’s blog post-to-Tweet conversion service. One click to set up your account, plus a couple clicks to submit your blog’s URL.
          </p> */}
          <div className='relative mb-24'>
            <div className={styles.faqItem}>
              <p className={styles.faqQuestion}>
                Does Blotter Tweet for me?
              </p>
              <p className={styles.faqAnswer}>
                Not yet! Currently, Blotter generates the copy for you to edit or Tweet as is. Tweets and threads are shown on a recommended schedule so it’s simple for you to use.
              </p>
              <p className={styles.faqAnswer}>
                This might be coming soon though 😉
              </p>
            </div>
            <div className={styles.faqItem}>
              <p className={styles.faqQuestion}>
                Can I try Blotter for free?
              </p>
              <p className={styles.faqAnswer}>
                Yes! Blotter will generate a sample of your 1-week Twitter Content Schedule <strong>for free</strong>, so you can get an idea of the quality you’ll be receiving. The free sample includes content for 2-4 Tweets and/or Twitter threads.
              </p>
            </div>
            <div className={styles.faqItem}>
              <p className={styles.faqQuestion}>
                How much does Blotter cost?
              </p>
              <p className={styles.faqAnswer}>
                Blotter offers a free sample, and is currently on sale for a limited-time launch price of just $9 for 9 Tweets/Twitter threads.
              </p>
            </div>
            <div className={styles.faqItem}>
              <p className={styles.faqQuestion}>
                How many Twitter threads vs single Tweets will I receive?
              </p>
              <p className={styles.faqAnswer}>
                The number of threads vs single Tweets varies based on the content in your blog posts, but your 9-item package you will typically include 2-7 threads, with the remainder as single Tweets.
              </p>
            </div>
            <div className={styles.faqItem}>
              <p className={styles.faqQuestion}>
                AI can’t write as well as me, why should I trust Blotter?
              </p>
              <p className={styles.faqAnswer}>
                We agree: there isn’t an AI copywriter in existence that can write as well as you.
              </p>
              <p className={styles.faqAnswer}>
                That’s why Blotter uses AI <strong>only</strong> to <strong>assist in converting</strong> your <em>high-quality</em> content into <em>high-quality</em> shareable snippets, in your own style, customized for each platform you use.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div id='join' className='bg-white'>
        <div className='max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-48 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between'>
          <h2 className='text-4xl font-extrabold tracking-tight text-gray-900 sm:text-2xl'>
            <span className='block'>Ready to see your blog posts converted to Tweets?</span>
            <span className='-mb-1 pb-1 block bg-gradient-to-r from-navy-400 to-navy-600 bg-clip-text text-transparent'>
              Get started right now
            </span>
          </h2>
          <div className='mb-5'>
            {/* <SubscribeForm /> */}
            <div className='relative max-w-sm mx-auto'>
              <LinkButton
                href='/sharing/start'
                variant='medium'
                color='rose'
                className='max-w-sm my-8 mx-auto text-center'
              >Get started in &lt;3 minutes&nbsp;&raquo;</LinkButton>
              <div className='text-[0.75rem] uppercase absolute -bottom-6 text-sky-700 font-bold left-0 right-0 text-center'>
                Start for free
                {/* — No CC required */}
              </div>
            </div>
          </div>
          {/* <div className='mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5'>
            <a
              href='#'
              className='flex items-center justify-center bg-gradient-to-r from-navy-400 to-navy-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-indigo-600 hover:to-indigo-800 focus:ring-2 focus:ring-offset-2 focus:ring-navy-400 focus:outline-none'
            >
              Learn more
            </a>
            <a
              href='#'
              className='flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-indigo-50 hover:bg-indigo-100'
            >
              Get started
            </a>
          </div> */}
        </div>
      </div>
    </main>

    <footer className='bg-gray-50' aria-labelledby='footer-heading'>
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8'>
        <div className='flex justify-center'>
          <Footer />
          {/* <div className='grid grid-cols-2 gap-8 xl:col-span-2'>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3 className='text-sm font-semibold text-gray-400 tracking-wider uppercase'>Solutions</h3>
                <ul role='list' className='mt-4 space-y-4'>
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className='text-base text-gray-700 hover:text-gray-900'>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='mt-12 md:mt-0'>
                <h3 className='text-sm font-semibold text-gray-400 tracking-wider uppercase'>Pages</h3>
                <ul role='list' className='mt-4 space-y-4'>
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className='text-base text-gray-700 hover:text-gray-900'>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3 className='text-sm font-semibold text-gray-400 tracking-wider uppercase'>Company</h3>
                <ul role='list' className='mt-4 space-y-4'>
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className='text-base text-gray-700 hover:text-gray-900'>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='mt-12 md:mt-0'>
                <h3 className='text-sm font-semibold text-gray-400 tracking-wider uppercase'>Legal</h3>
                <ul role='list' className='mt-4 space-y-4'>
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className='text-base text-gray-700 hover:text-gray-900'>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
          <div className='mb-12 xl:mt-0'>
            {/* <h3 className='text-sm font-semibold text-gray-400 tracking-wider uppercase'>
              Subscribe to our newsletter
            </h3>
            <p className='mt-4 text-base text-gray-700'>
              The latest news, articles, and resources, sent to your inbox weekly.
            </p> */}
          </div>
        </div>
        {/* <div className='mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16'>
          <div className='flex space-x-6 md:order-2'>
            {footerNavigation.social.map((item) => (
              <a key={item.name} href={item.href} className='text-gray-400 hover:text-gray-700'>
                <span className='sr-only'>{item.name}</span>
                <div aria-hidden='true'>{ item.icon }</div>
              </a>
            ))}
          </div>
          <p className='mt-8 text-base text-gray-400 md:mt-0 md:order-1'>
            &copy; 2020 Blotter, Inc. All rights reserved.
          </p>
        </div> */}
      </div>
    </footer>
  </div>

export default RepurposingView



const benefits = [
  {
    name: 'Save time',
    description: 'You’ve got a long to-do list full of things <em>only you</em> can do. Let Blotter put quality time back in your busy day.',
    icon: <ClockIcon className='text-rose-500 w-8 h-8' />,
  },
  {
    name: 'Avoid boredom',
    description: 'Crafting amazing content is rewarding, creative work. Repurposing is tedious + boring. Blotter handles it, so you don’t have to.',
    icon: <ImSleepy className='text-indigo-300 w-7 h-7' />,
  },
  {
    name: 'Grow your audience',
    description: 'You want to spread your writing to more readers, and SEO takes months to kick in. Blotter makes social growth easier.',
    icon: <UsersIcon className='text-emerald-400 w-8 h-8' />,
  },
  {
    name: 'Stay Consistent',
    description: 'To grow on Twitter, experts advise posting consistently. That takes work, and Blotter helps shoulder the load.',
    icon: <ChartBarIcon className='text-cyan-400 w-8 h-8' />,
  },
  // {
  //   name: 'Social Sharing',
  //   description: 'Many comment systems have social login. Blotter actually shares comments, automatically.',
  //   icon: <ShareIcon className='text-cyan-500 w-8 h-8' />,
  // },
  {
    name: 'Save Money',
    description: 'Time is money, and yours is valuable. Each minute Blotter saves you means money in your pocket.',
    icon: <CurrencyDollarIcon className='text-amber-400 w-8 h-8' />,
  },
  {
    name: 'Unlock your content',
    description: 'Your blog posts contain honest-to-goodness value, but it’s locked away. Blotter helps make the most of your work by sharing it.',
    icon: <SparklesIcon className='text-slate-300 w-8 h-8' />,
  },
]
