// import Link from 'components/Link'
import NextLink from 'next/link'
import styles from './footer.module.css'

const Footer = () => {
  const now = new Date()
  return <footer className={styles.footer}>
    <div className={styles.footerRow}>
      {/* <Link href='/'>Home</Link> */}
      {/* <Link href='/pricing'>Pricing</Link> */}
      {/* <Link href='/docs/how-it-works'>How&nbsp;it&nbsp;works</Link> */}
      {/* <Link href='/docs/starting-conversations'>Starting&nbsp;Conversations</Link> */}
    </div>
    <div className={styles.footerRow}>
      {/* <NextLink href='/feedback'>
        <a className={styles.linkEmphasized}>Feedback please!</a>
      </NextLink> */}
      <NextLink href='/terms'>
        <a className={styles.link}>Terms of Use</a>
      </NextLink>
      <NextLink href='/privacy'>
        <a className={styles.link}>Privacy Policy</a>
      </NextLink>
    </div>
    <div className={styles.footerRow}>&copy; Modern Bit and Pixel 2018 - { now.getFullYear() }</div>
  </footer>
}

export default Footer
