import PageForMarketing from 'components/PageForMarketing'
import PageWithTailwind from 'components/PageWithTailwind'
import RepurposingView from 'views/RepurposingView'

const index = () =>
  <PageForMarketing suppressBanners>
    <PageWithTailwind
      title='Your blog posts deserve to be seen'
      description='Blotter automatically turns your blog posts into engaging Twitter threads'
    >
      <RepurposingView />
    </PageWithTailwind>
  </PageForMarketing>

export const getStaticProps = () => {
  return {
    props: {},
  }
}

export default index
